<template>
  <div id="addModify">
    <div class="headerDetail">
        <a-page-header
        title="用户详情"
        style="padding:0"
        />
        <a-button type="primary" @click="goback">返回</a-button>
    </div>
    <div style="margin-top:20px">
        <div class="title">
            基本信息 &nbsp;&nbsp;<span v-show="formData.userState == '已注销'" style="color:red">已注销</span>
        </div>
        <a-row style="margin: 24px 12px">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-col :span="5">
                    <a-form-model-item label="用户ID：">{{formData.userNumber}}</a-form-model-item>
                    <a-form-model-item label="注册时间：">{{formData.gmtCreate}}</a-form-model-item>
                    <a-form-model-item label="生日">{{formData.birthday}}</a-form-model-item>
                </a-col>
                <a-col :span="5">
                    <a-form-model-item label="用户昵称：">{{formData.nickname}}</a-form-model-item>
                    <a-form-model-item label="注册渠道：">{{formData.registerSource}}</a-form-model-item>
                    <a-form-model-item label="行业：">{{formData.industry}}</a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="性别：">{{formData.gender == 1 ? "女" : formData.gender == 0 ? "男" : ""}}</a-form-model-item>
                    <a-form-model-item label="用户所属项目:" style="width:500px">
                        <div style="max-width:400px;marginLeft:10px">{{formData.userProjects && formData.userProjects}}</div>
                        <!-- <div style="max-width:400px;marginLeft:10px">{{formData.userProjects && formData.userProjects.join("、")}}</div> -->
                        <a-button v-if="this.$route.query.flag == 1" type="primary" @click="editProject">修改</a-button>
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="联系方式：">{{formData.mobile}}</a-form-model-item>
                </a-col>
            </a-form-model>
        </a-row>
    </div>
    <div style="margin-top:20px">
        <div class="title">
            认证信息
        </div>
        <a-row style="margin: 24px 12px">
            <div style="border-left:5px solid #108ee9;padding:0 5px">实名认证</div>
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-col :span="8">
                    <a-form-model-item label="姓名：">{{formData.realName}}</a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="身份证/护照：">{{formData.realIdentity}}</a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="性别：">{{formData.realSex == 1 ? "女" : formData.realSex == 0 ? "男" : ""}}</a-form-model-item>
                </a-col>
            </a-form-model>
        </a-row>
        <a-row style="margin:0 12px">
            <div style="border-left:5px solid #108ee9;padding:0 5px">企业认证</div>
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-col :span="8">
                    <a-form-model-item label="公司名称：">{{formData.firmName}}</a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="公司所属项目：" style="width:500px">
                        <div style="max-width:400px;marginLeft:10px">{{formData.firmProjectName}}</div>
                    </a-form-model-item>
                </a-col>
            </a-form-model>
        </a-row>
    </div>
    <div style="margin-top:20px">
        <div class="title">
            注册平台信息
        </div>
        <a-row style="margin: 24px 12px">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-col :span="8">
                    <a-form-model-item label="APP:">{{app}}</a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="微信小程序">{{WX}}</a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="PC：">{{PC}}</a-form-model-item>
                </a-col>
            </a-form-model>
        </a-row>
    </div>
    <div class="fotter">
      <a-button key="back" @click="goback"> 取消 </a-button>
      <a-button v-if="this.$route.query.flag == 1" key="submit" style="margin-left: 30px" type="primary" :loading="loading" @click="onSubmit">
        确认保存
      </a-button>
    </div>
    <a-modal
      title="修改用户所属项目"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p>
          <a-form-model>
              <a-form-model-item label="所属项目：">
                <a-select
                v-model="project"
                style="width: 200px"
                placeholder="全部"
                >
                <a-select-option v-for="option in optionsAll" :key="option.id" :value="option.id + ',' + option.project_name">
                    {{ option.project_name }}
                </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-form-model>
      </p>
    </a-modal>
  </div>
</template>

<script>
import * as api from "@/api/userMessage";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  data() {
    return {
      tenantId: JSON.parse(localStorage.getItem("user")),
      loading: false,
      visible: false,
      project: [],
      form: {
      },
      formData:{},
      optionsAll:[],
      rules: {},
      id:"",
      app:"未注册",
      WX:"未注册",
      PC:"未注册"
    };
  },
  mounted(){
    this.id = this.$route.query.val
    this.detailData()
    this.getProject()
  },
  methods: {
      async getProject(){
          const data = {
            page:1,
            size:999,
            tenant_id:this.tenantId.tenantId
        }
        const res = await api.getProject(data)
        if(res.code === "200"){
            this.optionsAll = res.data.projects
        }
      },
      async detailData(){
          const res = await api.getUserDetail(this.id)
          if(res.code === "200"){
              this.formData = res && res.data || {}
          }
        //   const arr = ["app_iyou","web_ies","WX_LOGIN","saas_platform"]
          if(this.formData.registryPlatform && this.formData.registryPlatform.indexOf("app_iyou") !== -1){
              this.app = "已注册"
          }
          if(this.formData.registryPlatform && this.formData.registryPlatform.indexOf("web_ies") !== -1 || this.formData.registryPlatform && this.formData.registryPlatform.indexOf("saas_platform") !== -1){
              this.PC = "已注册"
          }
          if(this.formData.registryPlatform && this.formData.registryPlatform.indexOf("WX_LOGIN") !== -1 || this.formData.registryPlatform && this.formData.registryPlatform.indexOf("wx_login") !== -1){
              this.WX = "已注册"
          }
      },
      goback(){
          this.$router.go(-1)
      },
      editProject(){
          this.visible = true
      },
    //提交
    onSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
            const data = {
                id:this.formData.id || "",
                userProject:this.project.length !== 0 ? this.project.split(",")[1] : "",
                projectId:this.project.length !== 0 ? this.project.split(",")[0] : "",
                // tenantId: this.tenantId.tenantId || "03ba723cf0800000",
                mobile:this.formData.mobile || "",
                userId:this.formData.userId
            }
            const res = await api.getUseredit(data)
            if(res.code === "200"){
                this.$message.success("保存成功")
                this.$router.go(-1)
            }else{
                this.$message.error(res.msg)
            }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleOk(){
        this.formData.userProjects = this.project.split(",")[1]
        // this.formData.userProjects = this.project.split(",")[1].split(",")
        this.visible = false
    },
    //取消
    handleCancel() {
      this.visible = false
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.form.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style scoped>
.headerDetail{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top:16px
}
::v-deep .ant-form-item {
  display: flex;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.title{
    font-weight: 700;
}

.fotter {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
